export const navigationItems = [
    {
        id: 'vicki',
        title: 'Vicki',
        link: '/vicki'
    },
    {
        id: 'order',
        title: 'Order',
        link: '/order'
    },
    {
        id: 'training',
        title: 'Training',
        link: '/training'
    },
    {
        id: 'support',
        title: 'Support',
        link: '/support'
    }
];