import React from 'react';

import './Vicki-page.scss';
import Video from "../../components/video/Video";
import PdfViewer from "../../components/pdf-viewer/PdfViewer";
import systemMediaSplash from "../../assets/video/vicki-page/system-media-splash.mp4";
import systemMediaWelcome from "../../assets/video/vicki-page/system-media-welcome.mp4";
import systemMediaThankYou from "../../assets/video/vicki-page/system-media-thank-you.mp4";
import systemMediaFailure from "../../assets/video/vicki-page/system-media-failure.mp4";
import systemMediaError from "../../assets/video/vicki-page/system-media-error.mp4";
import productPhoto1 from "../../assets/img/vicki-page/product-photo-1.webp";
import productPhoto2 from "../../assets/img/vicki-page/product-photo-2.webp";
import productPhoto3 from "../../assets/img/vicki-page/product-photo-1.webp";
import productPhoto4 from "../../assets/img/vicki-page/product-photo-2.webp";
import productPhoto5 from "../../assets/img/vicki-page/product-photo-1.webp";
import vickiPdf2 from "../../assets/pdf/vicki-page/vicki-2.pdf";

function VickiPage(props) {
    return (
        <div className={'vicki-page-wrapper'}>
            <section className={'section'}>
                <h2 className={'section-title'}>About Vicki</h2>

                <div className={'about-vicki-videos-wrapper'}>
                    <div className={'about-vicki-video'}>
                        <iframe src="https://www.youtube.com/embed/SVNsQO5EyBM?si=oSBRWjBBKjssBvoy"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                    <div className={'about-vicki-video'}>
                        <iframe src="https://www.youtube.com/embed/EMQpk9Xyqys?si=SWyZS2dJLT1nzejb"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                    <div className={'about-vicki-video'}>
                        <iframe src="https://www.youtube.com/embed/gAdExjZGhEg?si=joG1L5eYE385ALto"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                </div>
                
                <hr className={'divider'}/>
            </section>

            <section className={'section'}>
                <h2 className={'section-title'}>Vicki PDF</h2>

                <div className={'vicki-pdf-wrapper'}>
                    <PdfViewer file={vickiPdf2} />
                </div>
                
                <hr className={'divider'}/>
            </section>

            <section className={'section'}>
                <h2 className={'section-title'}>Product Specs</h2>

                <p className={'body-text'}>To be done...</p>
                
                <hr className={'divider'}/>
            </section>

            <section className={'section'}>
                <h2 className={'section-title'}>Planogram specs</h2>

                <p className={'body-text'}>To be done...</p>
                
                <hr className={'divider'}/>
            </section>

            <section className={'section'}>
                <h2 className={'section-title'}>FAQ</h2>

                <p className={'body-text'}>To be done...</p>
                
                <hr className={'divider'}/>
            </section>

            <section className={'section'}>
                <h2 className={'section-title mb-20'}>Vicki Media Criteria</h2>

                <p className={'body-text system-media-description mb-50'}>
                    Media is divided into two different categories in the dashboard, system media and product media
                </p>

                <hr className={'divider small'}/>
                
                <h3 className={'section-sub-title'}>System Media</h3>

                <p className={'body-text system-media-description mb-50'}>
                    System media includes 5 different video clips that play on specific events
                </p>
                
                <div className={'media-criteria-videos-wrapper'}>
                    <div className={'media-criteria-video'}><Video src={systemMediaSplash} /></div>
                    <div className={'media-criteria-video'}><Video src={systemMediaWelcome} /></div>
                    <div className={'media-criteria-video'}><Video src={systemMediaThankYou} /></div>
                    <div className={'media-criteria-video'}><Video src={systemMediaFailure} /></div>
                    <div className={'media-criteria-video'}><Video src={systemMediaError} /></div>
                </div>

                <hr className={'divider small'}/>
                
                <h3 className={'section-sub-title mt-50'}>Product Media</h3>

                <p className={'body-text system-media-description mb-50'}>
                    Product media is triggered when a customer picks up or puts back a product.
                    You have the ability to add different media in the form of video or photo to
                    each of those events (pickup or putback)
                </p>
                
                <div className={'media-criteria-videos-wrapper'}>
                    <div className={'media-criteria-video'}><Video src={systemMediaSplash} /></div>
                    <div className={'media-criteria-video'}><Video src={systemMediaWelcome} /></div>
                    <div className={'media-criteria-video'}><Video src={systemMediaThankYou} /></div>
                    <div className={'media-criteria-video'}><Video src={systemMediaFailure} /></div>
                    <div className={'media-criteria-video'}><Video src={systemMediaError} /></div>
                </div>

                <hr className={'divider small'}/>
                
                <h3 className={'section-sub-title mt-50'}>Product Photo</h3>

                <p className={'body-text system-media-description mb-50'}>
                    Each product can include a single photo. This photo is seen as a thumbnail by the customer
                    on the shopping cart. It is also used when refilling the machine to make sure the stocker
                    is loading up the correct product.
                </p>
                
                <div className={'media-criteria-photos-wrapper'}>
                    <div className={'media-criteria-photo'}><img src={productPhoto1} alt={'Product Photo'} /></div>
                    <div className={'media-criteria-photo'}><img src={productPhoto2} alt={'Product Photo'} /></div>
                    <div className={'media-criteria-photo'}><img src={productPhoto3} alt={'Product Photo'} /></div>
                    <div className={'media-criteria-photo'}><img src={productPhoto4} alt={'Product Photo'} /></div>
                    <div className={'media-criteria-photo'}><img src={productPhoto5} alt={'Product Photo'} /></div>
                </div>
            </section>
        </div>
    );
}

export default VickiPage;
