import React from 'react';

import './Order-page.scss';
import readinessFormPdf from "../../assets/doc/PF-Kiosk-Club-Readiness-Form.pdf";
import orderFormDoc from "../../assets/doc/PF-Kiosk-Order-Form.docx";

function OrderPage(props) {
    const receiverEmails = ['lisahanley@viatouchmedia.com' , 'andrea@viatouchmedia.com', 'dougp@viatouchmedia.com'];
    return (
        <div className={'order-page-wrapper'}>
            <section className={'section'}>
                <h2 className={'section-title'}>Readiness form</h2>
                <ol className={'list-wrapper body-text'}>
                    <li>
                        Please download
                        <a href={readinessFormPdf} download>Readiness Form</a>
                    </li>
                    <li>Fill out all the required info</li>
                    <li>
                        Attach Filled Readiness Form
                        <a href={"mailto:" + receiverEmails.join(',') + "?subject=PF Kiosk Club Readiness Form&body=Hello!%0D%0A%0D%0A%0D%0AAttached is the completed PF Kiosk Club Readiness Form for your reference.%0D%0A%0D%0A%0D%0AThanks,%0D%0APlanet Fitness"}>Here</a>
                    </li>
                </ol>
                <hr className={'divider'}/>
            </section>

            <section className={'section'}>
                <h2 className={'section-title'}>Order form</h2>

                <ol className={'list-wrapper body-text'}>
                    <li>
                        Please download
                        <a href={orderFormDoc} download>Order form</a>
                    </li>
                    <li>Fill out all the required info</li>
                    <li>
                        Attach Filled Order form
                        <a href={"mailto:" + receiverEmails.join(',') + "?subject=PF Kiosk Club Order Form&body=Hello!%0D%0A%0D%0A%0D%0AAttached is the completed PF Kiosk Club Order Form for your reference.%0D%0A%0D%0A%0D%0AThanks,%0D%0APlanet Fitness"}>Here</a>
                    </li>
                </ol>
            </section>
        </div>
    );
}

export default OrderPage;
