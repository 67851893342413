import './PdfViewer.scss'
import React, { useState } from "react";
import { Document, Page } from "react-pdf";

import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import useSize from "../../utils/window-resize";

import media from './../../styles/media.scss';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

function PdfViewer (props) {
    const [vickiPdfCurrentPage, setVickiPdfCurrentPage] = useState(0);
    const [vickiPdfPagesNumber, setVickiPdfPagesNumber] = useState();

    const windowSize = useSize();

    const onDocumentLoadSuccess = ({ numPages }) => {
        setVickiPdfPagesNumber(numPages);
    };
    
    const getPDFPageWidth = (width) => {
        if (width < media.xsMax.split('px')[0]) {
            return 300;
        } else if (width < media.lgMax.split('px')[0]) {
            return 400;
        } else {
            return 500;
        }
    }

    return (
        <div className={'pdf-viewer-wrapper'}>
            <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageIndex={vickiPdfCurrentPage} width={getPDFPageWidth(windowSize.width)} />
            </Document>
            
            <div className={'page-controls'}>
                <button
                    type={'button'}
                    className={'page-control-btn'}
                    disabled={vickiPdfCurrentPage === 0}
                    onClick={() => {
                        setVickiPdfCurrentPage(vickiPdfCurrentPage - 1);
                    }}
                >
                    ‹
                </button>

                <div className={'page-info'}>{vickiPdfCurrentPage + 1} of {vickiPdfPagesNumber}</div>

                <button
                    type={'button'}
                    className={'page-control-btn'}
                    disabled={vickiPdfCurrentPage === (vickiPdfPagesNumber - 1)}
                    onClick={() => {
                        setVickiPdfCurrentPage(vickiPdfCurrentPage + 1);
                    }}
                >
                    ›
                </button>
            </div>
        </div>
    );
}

export default PdfViewer;