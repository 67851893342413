import React from 'react';

import './Support-page.scss';

function SupportPage(props) {
    return (
        <div className={'support-page-wrapper'}>
            <section className={'section'}>
                <h2 className={'section-title'}>Email Support</h2>
                <p className={'body-text'}>vickipfsupport@viatouchmedia.com</p>
                <hr className={'divider'}/>
            </section>

            <section className={'section'}>
                <h2 className={'section-title'}>Phone Support</h2>
                <p className={'body-text'}>1-866-942-0804</p>
                <hr className={'divider'}/>
            </section>

            {/*<section className={'section'}>*/}
            {/*    <h2 className={'section-title'}>AI Chat bot support</h2>*/}
            {/*    <p className={'body-text'}>To be done...</p>*/}
            {/*    <hr className={'divider'}/>*/}
            {/*</section>*/}

            <section className={'section'}>
                <h2 className={'section-title'}>Team Org Chart</h2>
                <p className={'body-text'}>To be done...</p>
            </section>
        </div>
    );
}

export default SupportPage;
