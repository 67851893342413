import React from 'react';

import './Training-page.scss';

function TrainingPage(props) {
    return (
        <div className={'training-page-wrapper'}>
            <iframe style={{ border: '0', overflow: 'hidden', width: '1px', 'minWidth': '100%', 'maxWidth': '1232px' }}
                    src="https://simplebooklet.com/embed.php?wpKey=SkQt1upRaOtnVaeFpdgb8L&source=embed"
                    scrolling='no'
                    allowFullScreen></iframe>
        </div>
    );
}

export default TrainingPage;
