import './Video.scss'
import React from "react";

function Video (props) {
    return (
        <div className={'video-wrapper'}>
            <video src={props.src} controls />
        </div>
    );
}

export default Video;