import './Layout.scss'
import Header from "../header/Header";
import Footer from "../footer/Footer";
import React, { useEffect, useState } from "react";
import Backdrop from "../backdrop/Backdrop";

function Layout (props) {
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);

    useEffect(() => {
        const closeMenu = () => {
            mobileNavHandler(false);
        };
        window.addEventListener("resize", closeMenu);

        return () => {
            window.removeEventListener("resize", closeMenu);
        };
    }, []);

    const mobileNavHandler = isOpen => {
        setShowMobileNav(isOpen);
        setShowBackdrop(isOpen);
    };

    const backdropClickHandler = () => {
        setShowMobileNav(false);
        setShowBackdrop(false);
    };
    
    return (
        <div className={'layout'}>
            {showBackdrop && (
                <Backdrop onClick={backdropClickHandler} />
            )}
            <div className={'header-wrapper'}>
                <Header showMobileNav={showMobileNav}
                        onMobileNavOpen={mobileNavHandler.bind(this)} />
            </div>
            <div className={'main-content'}>
                <div className={'flex-1'}>
                    {props.children}
                </div>
                <div className={'footer-wrapper'}><Footer /></div>
            </div>
        </div>
    );
}

export default Layout;