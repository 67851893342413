import homeBannerSliderItem1 from "../assets/img/home-page/home-banner-slider-item-1.png";
import homeBannerSliderItem2 from "../assets/img/home-page/home-banner-slider-item-2.png";

export const HomeBannerSliderItems = [
    {
        imageSrc: homeBannerSliderItem1,
        imageAlt: 'Home Banner Slider Item 1',
        active: true
    },
    {
        imageSrc: homeBannerSliderItem2,
        imageAlt: 'Home Banner Slider Item 2',
        active: false
    }
];